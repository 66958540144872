import * as React from "react"
import styled from 'styled-components'
import Layout from "../components/Layout"
import AppSeo from "../components/AppSeo"

import salesmachine from '../images/salesmachine.svg'

const NotFoundPage = () => (
  <Layout>
    <AppSeo title="404: Not found" />
    <Section404>
      <Image404><img src={salesmachine} alt="Business sales machine" width={280} style={{}}></img></Image404>
      <Title404>404: Not Found</Title404>
      <Paragraph404>The page you&#39;re looking for doesn&#39;t exist...</Paragraph404>
    </Section404>
  </Layout>
)

export default NotFoundPage

const Section404 = styled.div`
    margin: 0 9.375rem;
    padding: 2rem calc((100vw - 1440px) / 2);

    @media screen and (max-width: 1020px){
      margin: 0 2rem;
    }

    @media screen and (max-width: 768px){
      margin: 0 1rem;
    }
`

const Image404 = styled.div`
  position: relative;
  right: -80px;

  @media screen and (max-width: 1020px){
    margin-bottom: -4%;
    right: -40px;
  }

  @media screen and (max-width: 768px){
    margin-bottom: -8%;
    right: -20px;
  }
`

const Title404 = styled.h1`
    margin-top: 2em;

    @media screen and (max-width: 1020px){

    }

    @media screen and (max-width: 768px){

    }
`

const Paragraph404 = styled.p`
    margin-bottom: 2em;

    @media screen and (max-width: 1020px){

    }

    @media screen and (max-width: 768px){

    }
`